<template>
  <div class="box_1">
    <div class="header">
      <div class="text" @click="goToPage('/bigscreen/menuPage')">巴林右旗企业</div> 
    </div>

    <div class="w_full h_95_p flex_row_around">
      <div class="map_box w_55_p h_full">
        <map-com @changeVillage="changeVillage" />
      </div>

      <div class="info_box w_43_p h_90">
        <div class="qi_ye h_full">
          <div class="qi_ye_content_box p_10 pt_30">
            <div class="color_fff fs_26">企业名称：{{ qiYeName }}</div>
            <div class="color_fff fs_26 mt_30">法人: {{ faRen }}</div>
            <div class="color_fff fs_26 mt_30">防疫员人数: {{ fangYiYuanRenShu }}</div>
              <div class="img_box w_full h_full mt_30">
                <div class="img full overflowY_auto">
                  <img class="full border_radius_10" :src="imgPath" alt="">
                  <!-- <el-carousel class="swiper color_fff" v-for="(item, index) in imgList" :key="index" direction="vertical" :autoplay="true">
                    <el-carousel-item class="yu_zhong_item">
                      <img class="full border_radius: 20" :src="item.path" alt="">
                    </el-carousel-item>
                  </el-carousel>     -->
                </div>
              </div>
          </div>

          <div class="qi_ye_content_box p_10 pt_30" v-if="villageName == '西拉沐沦苏木' || villageName == '大板镇'" >
            <div class="color_fff fs_26">企业名称：{{ qiYeName2 }}</div>
            <div class="color_fff fs_26 mt_30">法人: {{ faRen2 }}</div>
            <div class="color_fff fs_26 mt_30">防疫员人数: {{ fangYiYuanRenShu2 }}</div>
              <div class="img_box w_full h_full">
                <div class="img full overflowY_auto">
                  <el-carousel class="swiper color_fff" v-for="(item, index) in imgLists" :key="index" direction="vertical" :autoplay="true">
                    <el-carousel-item class="yu_zhong_item">
                      <img class="full border_radius: 20" :src="item.path" alt="">
                    </el-carousel-item>
                  </el-carousel>    
                </div>
              </div>
          </div>
        </div>
      </div>  
    </div>
  </div>
</template>

<script>
import mapCom from '../maps/baLinYouQiMap.vue'
export default {
  components: { mapCom },
  data() {
    return {
      villageName: '',
      qiYeName: '',
      faRen: '',
      fangYiYuanRenShu: 0,

      qiYeName2: '',
      faRen2: '',
      fangYiYuanRenShu2: 0,

      imgPath: '',
      imgList: [],
      imgLists: [],
      imgList1: [ 
        { path: require('../../../../assets/images/qi-ye/1/img1.png') },
        // { path: require('../../../../assets/images/qi-ye/1/img2.png') }
      ]
    }
  },

  methods: {
    // 跳转页面
    goToPage(val) {
      this.$router.push({
        path: val
      })
    },

    changeVillage(val) {
      this.villageName = val

      if (val == '宝日勿苏镇') {
        this.qiYeName = '巴林右旗益特格乐畜牧业服务有限公司'
        this.faRen = '阿拉坦仓',
        this.fangYiYuanRenShu = 23
        this.imgPath = require('../../../../assets/images/qi-ye/img1.png')
      } else if (val == '西拉沐沦苏木') {
        this.qiYeName = '巴林右旗金泉畜牧业服务有限公司'
        this.faRen = '阿拉坦宝力格',
        this.fangYiYuanRenShu = 17
        this.imgPath = ''

        this.qiYeName2 = '巴林右旗金泉畜牧业服务有限公司'
        this.faRen2 = '哈斯额日德尼',
        this.fangYiYuanRenShu2 = 6
        this.imgPath = ''
        // this.imgPath = require('../../../../assets/images/qi-ye/img3.png')
      } else if (val == '查干诺尔镇') {
        this.qiYeName = '巴林右旗额日其畜牧业服务有限责任公司'
        this.faRen = '乌力吉巴根',
        this.fangYiYuanRenShu = 17
        // this.imgPath = require('../../../../assets/images/qi-ye/img4.png')
      } else if (val == '大板镇') {
        this.qiYeName = '巴林右旗康泰畜牧业服务有限公司'
        this.faRen = '隋海波',
        this.fangYiYuanRenShu = 22
        this.imgPath = ''
        // this.imgPath = require('../../../../assets/images/qi-ye/img5.png')

        this.qiYeName2 = '巴林右旗胡瑞畜牧业科技服务有限公司'
        this.faRen2 = '特木热巴根',
        this.fangYiYuanRenShu2 = 12
        this.imgPath = ''
        // this.imgPath = require('../../../../assets/images/qi-ye/img6.png')
      } else if (val == '巴彦塔拉苏木') {
        this.qiYeName = '赤峰市塔本额尔敦农牧业服务有限公司'
        this.faRen = '额日和木',
        this.fangYiYuanRenShu = 15
        this.imgPath = ''
        // this.imgPath = require('../../../../assets/images/qi-ye/img7.png')
      } else if (val == '查干沐沦苏木') {
        this.qiYeName = '巴林右旗满都蜡畜牧业服务有限公司'
        this.faRen = '格日乐满都拉',
        this.fangYiYuanRenShu = 16
        this.imgPath = require('../../../../assets/images/qi-ye/img2.png')
        // this.imgPath = require('../../../../assets/images/qi-ye/img8.png')
      } else if (val == '幸福之路苏木') {
        this.qiYeName = '巴林右旗牧家乐畜牧业服务有限公司'
        this.faRen = '朝鲁巴根',
        this.fangYiYuanRenShu = 21
        this.imgPath = ''
        // this.imgPath = require('../../../../assets/images/qi-ye/img9.png')
      } else if (val == '巴彦琥硕镇') {
        this.qiYeName = '赤峰市奥沃特科牧业科技有限公司'
        this.faRen = '蒋云志',
        this.fangYiYuanRenShu = 12
        this.imgPath = ''
        // this.imgPath = require('../../../../assets/images/qi-ye/img10.png')
      } else if (val == '索博日嘎镇') {
        this.qiYeName = '巴林右旗红润畜牧服务有限责任公司'
        this.faRen = '特古斯巴特尔',
        this.fangYiYuanRenShu = 26
        this.imgPath = ''
        // this.imgPath = require('../../../../assets/images/qi-ye/img11.png')
      } 
      else {
        this.qiYeName = ''
        this.faRen = '',
        this.fangYiYuanRenShu = 0
        this.imgList = []
      }
    }
  }
}
</script>

<style scoped>
  .box_1 {
    width: 100%;
    /* height: 700px; */
    background-color: #2a303c;
    /* background-image: url('../../../../assets/images/ba-lin-you-qi/bgc1.png'); */
    /* background-size: 100% 100%; */
  }

  .header { 
    background-image: url('../../../../assets/images/ba-lin-you-qi/nav-bgc.png');
    background-size: 100% 100%;
  }
  .map_box {
    width: 60%;
    height: 100%;
    /* border: 1px solid #fff; */
  }
  .qi_ye {
    /* background-image: url('../../../../assets/images/ba-lin-you-qi/img5.png'); */
    background-color: #2a303c;
    /* border: 1px solid #fff; */
    border-radius: 20rpx;
    background-size: 100% 100%;
  }
</style>